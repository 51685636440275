import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

import { AiOutlineLoading } from 'react-icons/ai'

import useQuery from '../hooks/useQuery'
import Logo from '../assets/logo/DOHMH_logo_WEB.5fa9912eedcb63.72363842.png'

const Patient = () => {
  const query = useQuery()
  const id = query.get('id')
  const history = useHistory()

  useEffect(() => {
    axios
      .post(
        'https://us-central1-crx-beta-bsti.cloudfunctions.net/NYC-B-Patient-Validation',
        {
          id,
        }
      )
      .then((res) => {
        console.log(res.data)

        const status = res.data.status
        const redirect = res.data.url

        if (status === 'appointment out of time') {
          history.push('/reject')
          return
        } else {
          window.location.href = redirect + '/patient'
        }
      })
      .catch((e) => console.error(e.message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  return (
    <div className='flex justify-center items-center min-h-screen max-w-screen-sm mx-auto px-2 sm:px-4'>
      <div className='p-4 sm:p-8 flex flex-col items-center justify-center sm:shadow-xl text-center'>
        <img src={Logo} className='my-4' alt='DOHMH logo' />
        <h2 className='text-center text-xl mb-4'>
          You have been invited to join a video visit.
          <br /> We are checking if your video appointment is ready.
        </h2>
        <AiOutlineLoading className='animate-spin text-teal-peg text-6xl' />
        <p className='text-lg sm:mx-6 my-4'>Please wait...</p>
      </div>
    </div>
  )
}

export default Patient
